<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            11. Особенности распространения огня в зданиях повышенной этажности
          </v-card-title>
        </v-col>
        <v-col
        cols="12"
        class="pb-0"
        >
          <v-alert
            border="left"
            colored-border
            color="red"
            class="title font-weight-light pl-10"
            elevation="2"
          >
            <strong>Здания повышенной этажности </strong> сооружения от 10 до 25 этажей, имеют конструкции из несгораемых материалов с большими пределами огнестойкости.
          </v-alert>
          <v-card-text class="title font-weight-light mb-0 pb-0">
            <p>Согласно требованиям законодательных документов, ПБ зданий повышенной этажности обеспечивается наличием пожарной автоматики, в том числе автоматических установок пожаротушения (АУПТ).</p>
            <p>В высотных сооружениях должен быть внутренний пожарный водопровод.</p>
          </v-card-text>
        </v-col>
        <v-col cols="12">
          <v-card-title class="mt-0 pt-0">К противопожарной защите таких зданий предъявляются особые требования из-за:</v-card-title>
        </v-col>
        <v-col cols="12">
          <v-tabs v-model="tab" grow color="red">
            <v-tab>Факторы, затрудняющие тушение пожаров</v-tab>
            <v-tab>Особенности распространения пожаров</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/stopwatch.svg"
                        max-width="80"
                        src="img/material/stopwatch.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        быстрое распространение огня и токсичных продуктов горения вверх, внутри и снаружи здания
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/thermometer.svg"
                        max-width="80"
                        src="img/material/thermometer.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        высокая температура и задымление на путях эвакуации в районе горящих этажей
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/burning-house.svg"
                        max-width="80"
                        src="img/material/burning-house.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        сложность подачи средств тушения в верхние этажи здания
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/fire-truck.svg"
                        max-width="80"
                        src="img/material/fire-truck.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        наличие
                        <v-tooltip bottom color="red">
                          <template v-slot:activator="{ on }">
                            <span
                              class="blue lighten-4"
                              v-on="on"
                            > стилобата </span>
                          </template>
                          <span class="pa-5 subtitle-1 pl-0">общий цоколь, который относится сразу к нескольким зданиям</span>
                        </v-tooltip>
                        по периметру здания и отсутствие подъездных площадок, осложняющее установку пожарной техники
                      </v-card-text>
                    </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                      <v-img
                        lazy-src="img/material/helicopter.svg"
                        max-width="80"
                        src="img/material/helicopter.svg"
                        class="ma-1"
                      ></v-img>
                      <v-spacer></v-spacer>
                      <v-card-text class="title font-weight-light">
                        необходимость применения большого количества технических средств для введения аварийно-спасательных работ и ликвидации пожара
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="8">
                    <v-tabs
                      v-model="tab2"
                      color="red"
                      slider-color="white"
                      class="mt-10 red"
                      active-class="ptm-act-class"
                    >
                      <v-tab class="px-0">
                        <v-card-title>
                          <v-icon size="35">mdi-numeric-1-box-multiple-outline</v-icon>
                        </v-card-title>
                      </v-tab>
                      <v-tab class="px-0">
                        <v-card-title>
                          <v-icon size="35">mdi-numeric-2-box-multiple-outline</v-icon>
                        </v-card-title>
                      </v-tab>
                      <v-tab class="px-0">
                        <v-card-title>
                          <v-icon size="35">mdi-numeric-3-box-multiple-outline</v-icon>
                        </v-card-title>
                      </v-tab>
                      <v-tab class="px-0">
                        <v-card-title>
                          <v-icon size="35">mdi-numeric-4-box-multiple-outline</v-icon>
                        </v-card-title>
                      </v-tab>
                      <v-tab class="px-0">
                        <v-card-title>
                          <v-icon size="35">mdi-numeric-5-box-multiple-outline</v-icon>
                        </v-card-title>
                      </v-tab>
                      <v-tab class="px-0">
                        <v-card-title>
                          <v-icon size="35">mdi-numeric-6-box-multiple-outline</v-icon>
                        </v-card-title>
                      </v-tab>
                      <v-tab class="px-0">
                        <v-card-title>
                          <v-icon size="35">mdi-numeric-7-box-multiple-outline</v-icon>
                        </v-card-title>
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items transition="fade-transition" v-model="tab2">

                      <v-tab-item>
                        <v-card-text class="title font-weight-light mt-10">
                          Огонь быстро распространяется из-за разницы внешнего и внутреннего давлений сооружения. Инженерные сети по каналам проходят через здание снизу вверх, что создает дополнительную тягу.
                        </v-card-text>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card-text class="title font-weight-light mt-10">
                          По горючим облицовочным материалам пламя распространяется на верхний уровень за 20 минут.
                        </v-card-text>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card-text class="title font-weight-light mt-10">
                          При разрешении остекления тяга увеличивается, и языки пламени достигают верхних этажей.
                        </v-card-text>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card-text class="title font-weight-light mt-10">
                          Некоторые планировочные решения ускоряют процесс распространения огня по горизонтали.
                        </v-card-text>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card-text class="title font-weight-light mt-10 mb-0 pb-0">
                          Основные пути распространения огня и дыма:
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          лестничные клетки;
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          шахты лифтов;
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          каналы и короба для инженерных коммуникаций;
                        </v-card-text>
                        <v-card-text class="title font-weight-light mb-0 pb-0">
                          <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                          мусоропроводы и неплотности в перекрытиях.
                        </v-card-text>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card-text class="title font-weight-light mt-10">
                          Если очаг возгорания находится на нижних уровнях, дым заполняется всю лестничную клетку за 5 минут. При таком задымлении требуется противогазы и затрудняется поиск людей.
                        </v-card-text>
                      </v-tab-item>

                      <v-tab-item>
                        <v-card-text class="title font-weight-light mt-10">
                          <p>На 2-3 этажа выше очага пожара возникает высокотемпературная область 100-150°С. </p>
                          <p>Через нее невозможно пройти без защитных костюмов, что блокирует людей и предотвращает их эвакуацию.</p>
                        </v-card-text>
                      </v-tab-item>

                    </v-tabs-items>
                  </v-col>
                  <v-col cols="4">
                      <v-img
                        lazy-src="img/material/fire-b-2.svg"
                        max-width="100%"
                        src="img/material/fire-b-2.svg"
                        class="pa-2"
                      ></v-img>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
          <Hint></Hint>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null,
    tab2: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
  .ptm-act-class {
    color: #f44335;
  }
</style>
